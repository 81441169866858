import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import {
  Col,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  Row
} from "shards-react";
import {
  CommentsBlock,
  ConfidencePercents,
  DateTimeInput,
  Form,
  Preloader,
  SelectContactInput,
  TagsInput
} from "lib/common";
import { FiCheckCircle, FiDownload, FiExternalLink, FiLock } from "react-icons/fi";
import { bind } from "decko";
import { checkContactsFetching, getContactsData, getVendorsTags } from "selectors/contacts";
import { checkDocumentsFetching, getDocumentsData } from "selectors/documents";
import { checkEventsFetching } from "selectors/events";
import {
  checkMatchesFetching,
  getSelectedBusinessData,
  getSelectedBusinessId
} from "selectors/businesses";
import { connect } from "react-redux";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getBanksData, getCurrenciesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { v4 as uuid } from "uuid";
import { withRouter } from "react-router-dom";
import Constants from "const/Constants";
import ContactsActions from "actions/ContactsActions";
import DataConstants from "const/DataConstants";
import Documents from "utils/Documents";
import DocumentsActions from "actions/DocumentsActions";
import EditDocumentWindowContactBlock from "./lib/EditDocumentWindowContactBlock";
import EditDocumentWindowFooter from "./lib/EditDocumentWindowFooter";
import EditDocumentWindowHeader from "./lib/EditDocumentWindowHeader";
import EditDocumentWindowHistoryBlock from "./lib/EditDocumentWindowHistoryBlock";
import EditDocumentWindowModeSelector, {
  EDIT_DOCUMENT_WINDOW_MODES
} from "./lib/EditDocumentWindowModeSelector";
import IntegrationServices from "const/IntegrationServices";
import LinkDocumentsWindow from "./lib/LinkDocumentsWindow";
import MainApiActions from "actions/MainApiActions";
import MultiAmountWidget from "./lib/MultiAmountWidget";
import PdfViewer from "./lib/PdfViewer";
import PreAccountingComponent from "./lib/PreAccountingComponent";
import React, { PureComponent } from "react";
import ReactDom from "react-dom";
import SelectCurrencyInput from "lib/common/SelectCurrencyInput";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import ValidationSchemes from "const/ValidationSchemes";
import VatClassificationComponent from "./lib/VatClassificationComponent";
import classNames from "classnames";
import moment from "moment";

const mapStateToProps = (state) => ({
  banksData: getBanksData(state),
  textsData: getTextsData(state),
  vendorsTags: getVendorsTags(state),
  accountsData: getAccountsData(state),
  contactsData: getContactsData(state),
  documentsData: getDocumentsData(state),
  currenciesData: getCurrenciesData(state),
  fetchingData: checkDocumentsFetching(state),
  fetchingMatches: checkMatchesFetching(state),
  fetchingEventsData: checkEventsFetching(state),
  fetchingContacts: checkContactsFetching(state),
  selectedBusinessId: getSelectedBusinessId(state),
  activeOrganization: getActiveOrganization(state),
  selectedBusinessData: getSelectedBusinessData(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchContactsList: (...args) => dispatch(ContactsActions.fetchContactsList(...args)),
  fetchVendorsTagsList: (...args) => dispatch(ContactsActions.fetchVendorsTagsList(...args)),
  fetchAttachmentUrl: (...args) => dispatch(MainApiActions.fetchAttachmentUrl(...args)),
  fetchDocument: (...args) => dispatch(DocumentsActions.fetchDocument(...args)),
  showModalImages: (...args) => dispatch(UiActions.showModalImages(...args)),
  showModal: (...args) => dispatch(UiActions.showModal(...args)),
  addNewContact: (...args) => dispatch(ContactsActions.addNewContact(...args))
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class EditDocumentWindow extends PureComponent {
  static FIELDS_TO_COMPARE = [
    "advance",
    "amountBase",
    "amountCorrection",
    "amountVat",
    "amountVatRates",
    "currency",
    "description",
    "privateNote",
    "dueDate",
    "exportAs",
    "extra",
    "id",
    "issueDate",
    "lineItems",
    "linkDocumentsWindowOpened",
    "linkedDocumentsIds",
    "location",
    "newContactData",
    "originalDocumentId",
    "paid",
    "payTo",
    "paymentDate",
    "paymentType",
    "tags",
    "taxDate",
    "type",
    "vendorId",
    "pohodaExtra"
  ];

  static defaultProps = {
    recogniseData: {}
  };

  uniqueId = null;

  initialStateSnapshot = null;

  constructor(props) {
    super(props);

    const {
      DOCUMENT_PAYMENT_TYPES: { BUY }
    } = DataConstants;

    let {
      editData: {
        paymentType,
        location,
        address,
        payTo,
        extra,
        pohodaExtra = {}
      },
      selectedBusinessData: { vatPayer },
      editData = {},
      recogniseData,
      initialTab
    } = this.props;

    const amountVatRates = editData.amountVatRates || [];

    const buyPaymentType = paymentType === BUY;

    if (!Object.values(EDIT_DOCUMENT_WINDOW_MODES).includes(initialTab)) {
      initialTab = EDIT_DOCUMENT_WINDOW_MODES.DEFAULT;
    }

    const amountBase = Utils.toMoneyNumber(editData.amountBase, true, "....") || 0;

    const amountVat = Utils.toMoneyNumber(editData.amountVat) || 0;

    const { addresses: { recipient = {}, sender = {} } = {} } = recogniseData;

    const addressData = paymentType && (address || (buyPaymentType ? sender : recipient));

    this.uniqueId = uuid();

    this.state = {
      amountBase,
      amountVat,
      amount: Utils.toMoneyNumber(amountBase + amountVat),
      advance: !!editData.advance,
      files: null,
      id: editData.id || null,
      type: editData.type || "",
      paymentType: paymentType || "",
      exportAs: editData.exportAs || "",
      issueDate: editData.issueDate || "",
      dueDate: editData.dueDate || "",
      taxDate: editData.taxDate || "",
      paymentDate: editData.paymentDate || "",
      currency: (this.checkCurrencyValidity(editData.currency) && editData.currency) || "",
      amountCorrection: Utils.toMoneyNumber(editData.amountCorrection) || 0,
      amountVatRates: vatPayer ? amountVatRates : (
        amountVatRates.every(({ base }) => !base)
          ? amountVatRates.map((item) => ({ ...item, base: editData.amount }))
          : amountVatRates
      ),
      detailedTaxRates: Array.isArray(editData.detailedTaxRates) && editData.detailedTaxRates.length
        ? editData.detailedTaxRates
        : [],
      lineItems: (editData.lineItems || []).map((item) => {
        return item.quantity === undefined ? item : { ...item, quantity: +item.quantity };
      }),
      vendorId: editData.vendorId || "",
      location: { id: (location && location.id) || "", name: (location && location.name) || "" },
      originalDocumentId: editData.originalDocumentId || "",
      linkedDocumentsIds: editData.linkedDocumentsIds || [],
      description: editData.description || "",
      privateNote: editData.privateNote || "",
      tags: editData.tags || [],
      newContactData: null,
      selectedEditTab: initialTab,
      enabledButtons: !editData.id,
      linkDocumentsWindowOpened: false,
      paid: !!editData.paid,
      address: {
        ...(paymentType ? addressData : {}),
        vatId: (paymentType && addressData && addressData.vatId) || "",
        idNumber: (paymentType && addressData && addressData.idNumber) || "",
        name: (paymentType && addressData && addressData.name) || "",
        subType: ""
      },
      payTo: {
        bankAccount: (payTo && payTo.bankAccount) || "",
        bankCode: (payTo && payTo.bankCode) || "",
        bankName: (payTo && payTo.bankName) || "",
        serviceAccountId: (payTo && payTo.serviceAccountId) || ""
      },
      extra: {
        VS: (extra && extra.VS) || "",
        KS: (extra && extra.KS) || "",
        SS: (extra && extra.SS) || ""
      },
      pohodaExtra
    };
    this.initialStateSnapshot = JSON.parse(JSON.stringify(this.state));
  }

  get documentFrozen() {
    const { TO_REPORT, EXCLUDED, EXPORTED } = DataConstants.STATUSES;

    const { editData: { status } = {} } = this.props;

    return status === TO_REPORT || status === EXCLUDED || status === EXPORTED;
  }

  get extractedDocumentsData() {
    return this.props.documentsData.filter(({ status }) => status !== DataConstants.STATUSES.TO_EXTRACT);
  }

  get currentDocumentIndex() {
    return this.extractedDocumentsData.findIndex(({ id }) => id === this.props.editData.id);
  }

  getDefaultAmountVatRates() {
    const { amountBase, amountVat } = this.state;

    return [{ rate: 0, base: (+amountBase || 0) + (+amountVat || 0), value: 0 }];
  }

  getDocumentTypesList(paymentType) {
    const { DOCUMENT_TYPES: { INVOICE, CREDIT_NOTE, RECEIPT }, DOCUMENT_PAYMENT_TYPES: { BUY } } = DataConstants;

    const { textsData: { uiTexts, messages } } = this.props;

    const buyPayment = paymentType === BUY;

    if (!paymentType) {
      return [{ value: INVOICE, label: messages.selectPaymentOperationType }];
    }

    return [
      { value: INVOICE, label: buyPayment ? uiTexts.bill : uiTexts.invoice },
      { value: `${INVOICE}.advance`, label: buyPayment ? uiTexts.advanceBill : uiTexts.advanceInvoice },
      ...(buyPayment ? [{ value: RECEIPT, label: uiTexts.receipt }] : []),
      { value: CREDIT_NOTE, label: uiTexts.creditNote }
    ];
  }

  getDocumentPaymentTypesList() {
    const { DOCUMENT_PAYMENT_TYPES: { BUY, SELL }, DOCUMENT_TYPES } = DataConstants;

    const { textsData: { uiTexts } } = this.props;

    return this.state.type === DOCUMENT_TYPES.CREDIT_NOTE ? [
      { value: BUY, label: uiTexts.sellReturn },
      { value: SELL, label: uiTexts.buyReturn }
    ] : [
      { value: BUY, label: uiTexts.buyOperation },
      { value: SELL, label: uiTexts.sellOperation }
    ];
  }

  checkVatRates() {
    const {
      selectedBusinessData: { vatPayer }
    } = this.props;

    const {
      amountVatRates,
      issueDate
    } = this.state;

    if (!vatPayer || !amountVatRates.length) return;

    const vatRates = issueDate && moment(issueDate).isBefore(Constants.CZ_NEW_VAT_DATE)
      ? Constants.OLD_VAT_RATES : Constants.VAT_RATES;

    this.setState({
      amountVatRates: amountVatRates.filter(({ rate }) => vatRates.includes(rate))
    });
  }

  checkCurrencyValidity(currency) {
    return this.props.currenciesData.some(({ code }) => code === currency);
  }

  checkFormValidity({ enabledButtons, selectedEditTab, files, ...restState }) {
    const {
      DOCUMENT_VALIDATION_SCHEMA,
      RECEIPT_VALIDATION_SCHEMA
    } = ValidationSchemes;

    const { type, amountVat, amountBase } = restState;

    const { DOCUMENT_TYPES: { RECEIPT } } = DataConstants;

    const receiptType = type === RECEIPT;

    const validationSchema = receiptType ? RECEIPT_VALIDATION_SCHEMA : DOCUMENT_VALIDATION_SCHEMA;

    return this.checkAmountDataConsistence()
      && validationSchema.isValidSync(
        { ...restState, amountVat: amountVat || 0, amountBase: amountBase || 0 },
        { context: restState }
      );
  }

  checkFormHasChanges() {
    return !this.documentFrozen && !Utils.deepEqual(
      Utils.getProps(this.state, EditDocumentWindow.FIELDS_TO_COMPARE),
      Utils.getProps(this.initialStateSnapshot, EditDocumentWindow.FIELDS_TO_COMPARE)
    );
  }

  checkAmountDataConsistence() {
    const {
      editData,
      selectedBusinessData: { vatPayer },
      activeOrganization: { countryCode }
    } = this.props;

    return Documents.checkAmountDataConsistence({
      documentData: { ...this.state, status: editData.status },
      countryCode,
      vatPayer
    });
  }

  @bind
  updateContactData(searchBy, compareSimilarity = false) {
    const { DOCUMENT_PAYMENT_TYPES: { BUY }, CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

    const { selectedBusinessData, contactsData, fetchVendorsTagsList } = this.props;

    const { paymentType } = this.state;

    const { id, idNumber, vatId, name } = searchBy;

    const buyPaymentType = this.state.paymentType === BUY;

    const { extraData: { integrationService } = {} } = selectedBusinessData;

    const contactData = contactsData.find((contact) => {
      if (paymentType && integrationService) {
        if ((buyPaymentType && contact.subType !== VENDOR) || (!buyPaymentType && contact.subType !== CUSTOMER)) {
          return false;
        }
      }
      if (id && id === contact.id) return true;
      if (compareSimilarity) return Utils.checkSimilarityBy({ idNumber, vatId, name }, contact);

      return Utils.checkSimilarityBy({ idNumber, vatId, name }, contact, 1);
    });

    if (contactData) {
      this.setState(
        ({ address }) => ({
          vendorId: contactData.id,
          address: {
            ...address,
            subType: contactData.subType,
            name: contactData.name || "",
            idNumber: contactData.idNumber || "",
            vatId: contactData.vatId || ""
          }
        }),
        () => fetchVendorsTagsList(contactData.id)
      );
    } else this.setState({ vendorId: "" });

    return !!contactData;
  }

  selectEditTab(tab) {
    this.setState(
      { selectedEditTab: tab, newContactData: null },
      () => {
        const { envVars, history } = this.props;

        if (tab === EDIT_DOCUMENT_WINDOW_MODES.DEFAULT) tab = null;
        history.replace(
          `?${Utils.objectToQueryString({ ...envVars, editItem: `${this.props.editData.id}${tab ? `.${tab}` : ""}` })}`
        );
      }
    );
  }

  switchDocument(indexOffset) {
    this.setState(
      { enabledButtons: false },
      () => {
        this.closeWindow(false).then(() => {
          this.props.onDocumentSwitch(this.extractedDocumentsData[this.currentDocumentIndex + indexOffset].id);
        });
      }
    );
  }

  async closeWindow(result) {
    const {
      textsData: { uiTexts, messages },
      banksData,
      selectedBusinessData,
      showModal,
      onClose
    } = this.props;

    const {
      advance,
      selectedEditTab,
      newContactData,
      files,
      type,
      paymentType,
      currency,
      amount,
      amountBase,
      amountVat,
      amountCorrection,
      amountVatRates,
      address,
      payTo,
      recogniseData,
      fullDocumentLoaded,
      lineItems,
      ...restState
    } = this.state;

    const documentResult = {
      ...restState,
      files,
      type,
      advance,
      amountBase,
      amountVat,
      paymentType: paymentType || undefined,
      currency: currency || undefined,
      amountCorrection: amountCorrection ? Utils.toMoneyNumber(amountCorrection) : undefined,
      amountVatRates: selectedBusinessData.vatPayer ? amountVatRates : null,
      payTo: {
        ...payTo,
        bankName: (banksData.find(({ code }) => code === payTo.bankName) || { label: "" }).label
      },
      lineItems: lineItems.map(({ newLineItem, item, ...rest }) => {
        return { ...rest, item: item?.name ? item : undefined };
      })
    };

    let modalResult = result;

    if (!modalResult && this.checkFormHasChanges()) {
      modalResult = await showModal(messages.unsavedChangesWarning, uiTexts.warning, true, "sm", uiTexts.yes, uiTexts.no);
    }

    await onClose(...(modalResult ? [documentResult, result.toReport, result.toExclude] : []));
  }

  @bind
  calculateLineItemsData(lineItems) {
    const { activeOrganization: { countryCode } } = this.props;

    const { paymentType } = this.state;

    return Documents.calculateLineItemsData({ lineItems, paymentType, countryCode });
  }

  toggleModal() {}

  componentDidMount() {
    const { editData = {}, fetchContactsList, fetchDocument } = this.props;

    const { paymentType, address } = this.state;

    fetchDocument(editData.id).then((response) => {
      this.setState({
        recogniseData: response?.recogniseData || {},
        fullDocumentLoaded: true
      });
    });

    fetchContactsList(false, true).then((contactsData) => {
      if (editData.vendorId) {
        if (contactsData && contactsData.length && paymentType) this.updateContactData({ id: editData.vendorId });
      } else if (paymentType) {
        const { name, idNumber, vatId } = address;

        const condition = editData && editData.status === DataConstants.STATUSES.TO_REVIEW && !this.documentFrozen;

        if (condition) this.updateContactData({ idNumber, vatId, name }, true);
      }
    });
    if (editData.id) {
      this.switchButtonsEnableTimeoutId = setTimeout(
        () => this.setState({ enabledButtons: true }),
        Constants.EDIT_DOCUMENT_WINDOW_BUTTONS_ENABLE_TIMEOUT
      );
    }
    this.checkVatRates();
  }

  componentDidUpdate(prevProps) {
    const { vendorId, address } = this.state;

    if (!vendorId && this.props.contactsData !== prevProps.contactsData) {
      const { name, idNumber, vatId } = address;

      this.updateContactData({ idNumber, vatId, name }, true);
    }
  }

  componentWillUnmount() {
    if (this.switchButtonsEnableTimeoutId) clearTimeout(this.switchButtonsEnableTimeoutId);
  }

  @bind
  handleClose(result) {
    this.closeWindow(result);
  }

  handleFormSubmit(event) {
    event.preventDefault();
  }

  @bind
  handleDownloadLinkClick(event) {
    event.preventDefault();

    const { editData: { attachment = {} } = {}, fetchAttachmentUrl } = this.props;

    fetchAttachmentUrl(attachment, true).then((link) => link && Utils.downloadContent(link));
  }

  @bind
  handleExternalLinkClick(event) {
    event.preventDefault();

    const { editData: { extraData: { xeroSourceUrl, zohoSourceUrl } = {} } = {} } = this.props;

    window.open(xeroSourceUrl || zohoSourceUrl, "_blank");
  }

  @bind
  handleEditContactBlockContactUnlink() {
    this.setState(() => {
      return { vendorId: "", address: { subType: "" } };
    },
    this.selectEditTab(EDIT_DOCUMENT_WINDOW_MODES.DEFAULT));
  }

  @bind
  handlePaymentTypeSelectChange({ target: { value } }) {
    const {
      DOCUMENT_TYPES: { INVOICE, CREDIT_NOTE },
      DOCUMENT_PAYMENT_TYPES: { BUY },
      STATUSES
    } = DataConstants;

    const {
      editData,
      recogniseData: { addresses: { recipient = {}, sender = {} } = {} }
    } = this.props;

    const { type, payTo: { serviceAccountId, ...restPayTo } } = this.state;

    const { name, idNumber, vatId, ...restAddress } = value === BUY ? sender : recipient;

    const condition = editData.status === STATUSES.TO_REVIEW;

    this.setState(
      ({ address }) => {
        return {
          paymentType: value,
          type: type === CREDIT_NOTE ? type : INVOICE,
          exportAs: "",
          paid: false,
          payTo: restPayTo,
          address: { ...address, subType: "" }
        };
      },
      () => {
        if (condition) {
          const result = this.updateContactData({ idNumber, vatId, name }, true);

          if (!result) {
            this.setState({
              address: {
                ...restAddress,
                name: name || "",
                idNumber: idNumber || "",
                vatId: vatId || "",
                subType: ""
              }
            });
          }
        }
      }
    );
  }

  @bind
  handleTypeSelectChange({ target: { value: rawValue } }) {
    const {
      DOCUMENT_PAYMENT_TYPES,
      DOCUMENT_TYPES: { CREDIT_NOTE }
    } = DataConstants;

    const [value, advance] = (rawValue || "").split(".");

    const { type, paymentType, payTo: { serviceAccountId, ...restPayTo } } = this.state;

    const creditNoteTypeChanged = value === CREDIT_NOTE || type === CREDIT_NOTE;

    this.setState({
      type: value,
      advance: advance === "advance",
      exportAs: "",
      paid: false,
      payTo: restPayTo,
      paymentType: creditNoteTypeChanged
        ? Object.values(DOCUMENT_PAYMENT_TYPES).find((item) => item !== paymentType)
        : paymentType,
      linkedDocumentsIds: []
    });
  }

  @bind
  handleIssueDateInputChange(value) {
    this.setState({ issueDate: value ? Utils.formatApiDate(value) : "" }, () => this.checkVatRates());
  }

  @bind
  handleDueDateInputChange(value) {
    this.setState({ dueDate: value ? Utils.formatApiDate(value) : "" });
  }

  @bind
  handleTaxDateInputChange(value) {
    this.setState({ taxDate: value ? Utils.formatApiDate(value) : "" });
  }

  @bind
  handleAmountBaseInputChange({ target: { value } }) {
    value = parseFloat(value);
    this.setState({ amountBase: isNaN(value) ? "" : Math.abs(value) });
  }

  @bind
  handleDetailedTaxRateInputChange({ target: { value, name } }) {
    const [, rateId] = name.split(".");

    this.setState(({ detailedTaxRates }) => {
      const newDetailedTaxRates = detailedTaxRates.map((item) => {
        if (item.id === rateId) {
          value = parseFloat(value);

          return { ...item, value: isNaN(value) ? "" : value };
        }

        return item;
      });

      return {
        detailedTaxRates: newDetailedTaxRates,
        amountVat: newDetailedTaxRates.reduce((aggregator, currentValue) => aggregator + +(currentValue.value || 0), 0)
      };
    });
  }

  @bind
  handleDetailedTaxRateInputFocus({ target }) {
    target.setAttribute("type", "number");
  }

  @bind
  handleDetailedTaxRateInputBlur({ target, target: { value, name } }) {
    const [, rateId] = name.split(".");

    this.setState(({ detailedTaxRates }) => {
      const newDetailedTaxRates = detailedTaxRates.map((item) => {
        if (item.id === rateId) {
          value = parseFloat(value);

          return { ...item, value: isNaN(value) ? 0 : Utils.toMoneyNumber(value) };
        }

        return item;
      });

      return {
        detailedTaxRates: newDetailedTaxRates,
        amountVat: newDetailedTaxRates.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0)
      };
    });
    target.setAttribute("type", "text");
  }

  @bind
  handleCurrencySelectChange({ target: { value } }) {
    this.setState({ currency: value });
  }

  @bind
  handleMultiAmountSelectChange({ value, correction }) {
    this.setState({
      amountVatRates: value,
      amountBase: value.reduce((aggregator, currentValue) => aggregator + currentValue.base || 0, 0),
      amountVat: value.reduce((aggregator, currentValue) => aggregator + currentValue.value || 0, 0),
      amountCorrection: correction
    });
  }

  @bind
  handleContactInputChange({ target: { value } }) {
    this.setState(({ address }) => ({ address: { ...address, name: value }, vendorId: "" }));
  }

  @bind
  handleContactInputAutoComplete({ target: { value } }) {
    this.updateContactData({ name: value });
  }

  @bind
  handleContactInputContactOpen() {
    this.selectEditTab(EDIT_DOCUMENT_WINDOW_MODES.CONTACT);
  }

  @bind
  handlePayToInputChange({ target: { value, name } }) {
    const [, fieldName] = name.split(".");

    this.setState((prevState) => {
      const newState = { payTo: { ...prevState.payTo, [fieldName]: value } };

      if (fieldName === "bankCode") {
        if (value === "") newState.payTo.bankName = "";
        else newState.payTo.bankName = value;
      }
      if (fieldName === "bankName") newState.payTo.bankCode = value;
      if (fieldName === "serviceAccountId") {
        newState.paid = true;
        if (!prevState.paymentDate && (prevState.dueDate || prevState.issueDate)) {
          newState.paymentDate = Utils.formatApiDate(prevState.dueDate || prevState.issueDate);
        }
      }

      return newState;
    });
  }

  @bind
  handleOriginalIdInputChange({ target: { value } }) {
    this.setState({ originalDocumentId: value });
  }

  @bind
  handleExtraInputChange({ target: { value, name } }) {
    const [, fieldName] = name.split(".");

    this.setState((prevState) => ({ extra: { ...prevState.extra, [fieldName]: value } }));
  }

  @bind
  handleDescriptionInputChange({ target: { value } }) {
    this.setState({ description: value });
  }

  @bind
  handleTagsInputChange({ target: { value } }) {
    this.setState({ tags: value || [] });
  }

  @bind
  handleLinkDocumentsWindowChange(value) {
    this.setState({ linkedDocumentsIds: value });
  }

  @bind
  handleLinkedDocumentsInputClick() {
    this.setState({ linkDocumentsWindowOpened: true });
  }

  @bind
  handleLineItemsInputClick() {
    this.selectEditTab(EDIT_DOCUMENT_WINDOW_MODES.LINE_ITEMS);
  }

  @bind
  handleHeaderDocumentSwitch(indexOffset) {
    this.switchDocument(indexOffset);
  }

  @bind
  handleModeSelectorChange(selectedEditTab) {
    this.selectEditTab(selectedEditTab);
  }

  @bind
  handleEditContactBlockDataChange(newContactData) {
    this.setState({ newContactData });
  }

  @bind
  handleAdditionalBlockClose(result) {
    const { DEFAULT, CONTACT } = EDIT_DOCUMENT_WINDOW_MODES;

    if (result) {
      const onClose = {
        [CONTACT]: () => {
          const { addNewContact, fetchVendorsTagsList } = this.props;

          const { currency } = this.state;

          addNewContact({ currency: currency || undefined, ...result }).then((contact) => {
            if (contact) this.setState(
              {
                vendorId: contact.id,
                address: { ...contact }
              },
              () => {
                fetchVendorsTagsList(contact.id);
              }
            );
          });
        }
      }[this.state.selectedEditTab];

      if (onClose) onClose();
    }
    this.selectEditTab(DEFAULT);
  }

  @bind
  handleLinkDocumentsWindowClose() {
    this.setState({ linkDocumentsWindowOpened: false });
  }

  @bind
  handleVatClassificationChange(vatClassification) {
    this.setState((prev) => {
      return {
        pohodaExtra: {
          ...prev.pohodaExtra,
          vatClassification
        }
      };
    });
  }

  @bind
  handlePreAccountingChange(preAccounting) {
    this.setState((prev) => {
      return {
        pohodaExtra: {
          ...prev.pohodaExtra,
          preAccounting
        }
      };
    });
  }

  renderEmptyConfidenceAddon() {
    const { recogniseData: { confidence } } = this.props;

    if (!confidence) return null;

    return (
      <InputGroupAddon type="append">
        <InputGroupText>
          <span>{Constants.EMPTY_PLACEHOLDER}</span>
        </InputGroupText>
      </InputGroupAddon>
    );
  }

  renderConfidenceAddon(fieldsNames, useMax = false) {
    fieldsNames = Array.isArray(fieldsNames) ? fieldsNames : [fieldsNames];

    const { editData, recogniseData: { confidence = {} } } = this.props;

    const confidenceValues = fieldsNames.map((value) => Utils.getPropertyByPath(confidence, value) || 0);

    const fieldsValuesChanged = fieldsNames.some((value) => {
      return Utils.getPropertyByPath(editData, value) !== Utils.getPropertyByPath(this.state, value);
    });

    const confidenceValue = confidenceValues.length && !fieldsValuesChanged
      ? (useMax ? Math.max(...confidenceValues) : Utils.getAverageNumber(confidenceValues))
      : 0;

    if (!Math.floor(confidenceValue * Constants.PERCENTS_MULTIPLIER)) {
      return this.renderEmptyConfidenceAddon();
    }

    return (
      <InputGroupAddon type="append">
        <InputGroupText>
          <ConfidencePercents background value={confidenceValue}>
            {confidenceValue === 1 ? <FiCheckCircle /> : null}
          </ConfidencePercents>
        </InputGroupText>
      </InputGroupAddon>
    );
  }

  renderPaymentTypeInputGroup() {
    const { paymentType } = this.state;

    const { textsData: { uiTexts }, fetchingContacts } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.operation}</span></InputGroupText>
        </InputGroupAddon>
        <FormSelect
          disabled={this.documentFrozen || fetchingContacts}
          value={paymentType}
          placeholder={paymentType ? undefined : ""}
          invalid={!paymentType}
          onChange={this.handlePaymentTypeSelectChange}>
          {!paymentType && <option value="">{uiTexts.selectPaymentOperationType}</option>}
          {this.getDocumentPaymentTypesList().map(({ value, label }) => {
            return <option key={value} value={value}>{label}</option>;
          })}
        </FormSelect>
        {paymentType
          ? this.renderConfidenceAddon("paymentType")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderDocumentTypeInputGroup() {
    const { type, advance, paymentType } = this.state;

    const { textsData: { uiTexts }, editData, fetchingContacts } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.type}</span></InputGroupText>
        </InputGroupAddon>
        <FormSelect
          disabled={this.documentFrozen || !paymentType || fetchingContacts}
          value={type ? `${type}${advance ? ".advance" : ""}` : ""}
          placeholder={type ? undefined : ""}
          invalid={!type}
          onChange={this.handleTypeSelectChange}>
          {this.getDocumentTypesList(paymentType).map(({ value, label }) => {
            return <option key={value} value={value}>{label}</option>;
          })}
        </FormSelect>
        {editData.paymentType && type
          ? this.renderConfidenceAddon(["paymentType", "type"])
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderOriginalIdInputGroup() {
    const { originalDocumentId, paid } = this.state;

    const { textsData: { uiTexts } } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{`${uiTexts.document} #`}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput
          disabled={this.documentFrozen}
          name="originalDocumentId"
          placeholder={uiTexts.enterDocumentId}
          invalid={paid && !originalDocumentId}
          value={originalDocumentId}
          onChange={this.handleOriginalIdInputChange} />
        {originalDocumentId
          ? this.renderConfidenceAddon("originalDocumentId")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderLinkedDocumentsInputGroup() {
    const { type, linkedDocumentsIds } = this.state;

    const { textsData: { uiTexts } } = this.props;

    const creditNoteType = type === DataConstants.DOCUMENT_TYPES.CREDIT_NOTE;

    const value = linkedDocumentsIds.length
      ? `${creditNoteType ? uiTexts.documents : uiTexts.creditNotes}: ${linkedDocumentsIds.length}`
      : "";

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.linked}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput
          readOnly
          placeholder={uiTexts.addLinkedDocuments}
          className={Css.expandableInput}
          value={value}
          onClick={this.handleLinkedDocumentsInputClick} />
        {this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderAmountInputGroup() {
    const {
      textsData: { uiTexts },
      selectedBusinessData: { vatPayer }
    } = this.props;

    const {
      amountVatRates,
      amountBase,
      amountCorrection,
      currency,
      issueDate
    } = this.state;

    return (
      <InputGroup className={Css.amountInputGroup}>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.total}</span></InputGroupText>
        </InputGroupAddon>
        {vatPayer
          ? <MultiAmountWidget
            base
            calculateInput
            inputClassName={Css.expandableInput}
            value={amountVatRates.length ? amountVatRates : this.getDefaultAmountVatRates()}
            correction={amountCorrection}
            invalid={!this.documentFrozen && (!amountVatRates.length || !this.checkAmountDataConsistence())}
            vatPayer={vatPayer}
            issueDate={issueDate}
            disabledFields={this.documentFrozen}
            onChange={this.handleMultiAmountSelectChange} />
          : <FormInput
            type="number"
            step="any"
            min={0}
            disabled={this.documentFrozen}
            value={(amountBase || amountBase === 0) ? amountBase : ""}
            invalid={!this.checkAmountDataConsistence()}
            placeholder={uiTexts.enterTotal}
            onChange={this.handleAmountBaseInputChange} />}

        <SelectCurrencyInput
          disabled={this.documentFrozen}
          value={currency}
          invalid={!currency}
          placeholder={Constants.EMPTY_PLACEHOLDER}
          className={Css.selectCurrency}
          onChange={this.handleCurrencySelectChange} />
        {amountBase
          ? this.renderConfidenceAddon(["amountBase", "amountVat", "currency"])
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderAmountBaseInputGroup() {
    const { currency, amountBase } = this.state;

    const { textsData: { uiTexts } } = this.props;

    return (
      <InputGroup className={Css.amountInputGroup}>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.subtotal}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput
          readOnly
          value={+amountBase || 0}
          className={Css.expandableInput}
          onClick={this.handleLineItemsInputClick} />
        <SelectCurrencyInput
          value={currency}
          className={Css.selectCurrency}
          disabled={this.documentFrozen}
          placeholder={Constants.EMPTY_PLACEHOLDER}
          invalid={!currency}
          onChange={this.handleCurrencySelectChange} />
        {amountBase
          ? this.renderConfidenceAddon(["amountBase", "currency"])
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderAmountVatInputGroup() {
    const {
      textsData: { uiTexts },
      selectedBusinessData: { vatPayer }
    } = this.props;

    const {
      amountVatRates,
      amountVat,
      amountCorrection,
      issueDate
    } = this.state;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.vat}</span></InputGroupText>
        </InputGroupAddon>
        <MultiAmountWidget
          vat
          calculateInput
          inputClassName={Css.expandableInput}
          value={amountVatRates.length ? amountVatRates : this.getDefaultAmountVatRates()}
          correction={amountCorrection}
          invalid={!this.documentFrozen && vatPayer
              && (!amountVatRates.length || !this.checkAmountDataConsistence())}
          vatPayer={vatPayer}
          issueDate={issueDate}
          disabled={!vatPayer}
          disabledFields={!vatPayer || this.documentFrozen}
          onChange={this.handleMultiAmountSelectChange} />

        {vatPayer && amountVat
          ? this.renderConfidenceAddon(["amountBase", "amountVat"])
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderDetailedTaxRateInputGroup(rateId, rateName) {
    const { textsData: { uiTexts } } = this.props;

    const { detailedTaxRates } = this.state;

    const taxRateData = detailedTaxRates.find(({ id }) => id === rateId) || {};

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText title={rateName}>{rateName || uiTexts.vat}</InputGroupText>
        </InputGroupAddon>
        <FormInput
          type="text"
          step="0.01"
          name={`detailedTaxRates.${rateId}`}
          disabled={this.documentFrozen}
          value={(taxRateData.value || taxRateData.value === 0) ? +taxRateData.value : ""}
          onChange={this.handleDetailedTaxRateInputChange}
          onFocus={this.handleDetailedTaxRateInputFocus}
          onBlur={this.handleDetailedTaxRateInputBlur} />
        {this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderIssueDateInputGroup() {
    const { issueDate } = this.state;

    const { textsData: { uiTexts } } = this.props;

    const label = uiTexts.issueDate;

    const placeholder = uiTexts.enterIssueDate;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{label}</span></InputGroupText>
        </InputGroupAddon>
        <DateTimeInput
          disabled={this.documentFrozen}
          placeholder={placeholder}
          value={issueDate}
          invalid={!issueDate || moment.utc(issueDate).isAfter(moment.utc(Utils.formatNoTimeZoneDate(new Date(), false)))}
          onChange={this.handleIssueDateInputChange} />
        {issueDate
          ? this.renderConfidenceAddon("issueDate")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderDueDateInputGroup() {
    const { DEFAULT_DOCUMENT_DUE_DATE_DAYS_OFFSET } = Constants;

    const { dueDate, issueDate } = this.state;

    const { textsData: { uiTexts } } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.dueDate}</span></InputGroupText>
        </InputGroupAddon>
        <DateTimeInput
          disabled={this.documentFrozen}
          placeholder={uiTexts.enterDueDate}
          value={dueDate}
          invalid={dueDate && issueDate && moment.utc(issueDate).isAfter(moment.utc(dueDate))}
          openToDate={!dueDate && issueDate && moment.utc(issueDate).add(DEFAULT_DOCUMENT_DUE_DATE_DAYS_OFFSET, "days")}
          onChange={this.handleDueDateInputChange} />
        {dueDate
          ? this.renderConfidenceAddon("dueDate")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderTaxDateInputGroup() {
    const { taxDate, issueDate } = this.state;

    const { textsData: { uiTexts }, selectedBusinessData: { vatPayer } } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.taxDate}</span></InputGroupText>
        </InputGroupAddon>
        <DateTimeInput
          showRawValue={!vatPayer}
          disabled={this.documentFrozen || !vatPayer}
          placeholder={uiTexts.enterTaxDate}
          value={vatPayer ? taxDate : uiTexts.notVatPayer}
          invalid={vatPayer && taxDate && issueDate && moment.utc(issueDate).isAfter(moment.utc(taxDate))}
          openToDate={!taxDate && issueDate}
          onChange={this.handleTaxDateInputChange} />
        {taxDate
          ? this.renderConfidenceAddon("taxDate")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderBusinessNameInputGroup() {
    const { paymentType, vendorId, address } = this.state;

    const {
      textsData: { uiTexts, messages },
      recogniseData,
      editData,
      selectedBusinessData,
      fetchingContacts
    } = this.props;

    const { extraData: { integrationService } = {} } = selectedBusinessData;

    const { name } = address;

    const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

    const confidenceDataPath = `addresses.${buyPaymentType ? "sender" : "recipient"}`;

    const enabled = !!paymentType && !fetchingContacts;

    const nameChanged = name && paymentType && name !== Utils.getPropertyByPath(recogniseData, `${confidenceDataPath}.name`);

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <span>{paymentType ? (buyPaymentType ? uiTexts.vendor : uiTexts.customer) : uiTexts.businessName}</span>
          </InputGroupText>
        </InputGroupAddon>
        <SelectContactInput
          dropup
          onlyVendors={paymentType && buyPaymentType && integrationService}
          onlyCustomers={paymentType && !buyPaymentType && integrationService}
          name={this.uniqueId}
          className={Css.selectContactInput}
          vendorId={vendorId}
          value={paymentType ? (name || "") : messages.selectDocumentType}
          disabled={!enabled || this.documentFrozen}
          highlight={!!name && !vendorId}
          invalid={!vendorId}
          onChange={this.handleContactInputChange}
          onAutoComplete={this.handleContactInputAutoComplete}
          onContactOpen={this.handleContactInputContactOpen} />
        {paymentType && name && (!nameChanged || (vendorId && vendorId === editData.vendorId))
          ? this.renderConfidenceAddon(
            vendorId
              ? [`${confidenceDataPath}.name`, `${confidenceDataPath}.idNumber`, `${confidenceDataPath}.vatId`]
              : `${confidenceDataPath}.name`,
            true
          )
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderBusinessIdInputGroup() {
    const { paymentType, vendorId, address: { idNumber } } = this.state;

    const { textsData: { uiTexts } } = this.props;

    const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

    const confidenceDataPath = `addresses.${buyPaymentType ? "sender" : "recipient"}`;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.businessId}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput disabled value={idNumber || ""} />
        {paymentType && idNumber && !vendorId
          ? this.renderConfidenceAddon(`${confidenceDataPath}.idNumber`)
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderVatIdInputGroup() {
    const { paymentType, vendorId, address: { vatId } } = this.state;

    const { textsData: { uiTexts } } = this.props;

    const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

    const confidenceDataPath = `addresses.${buyPaymentType ? "sender" : "recipient"}`;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.vatId}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput disabled value={vatId || ""} />
        {paymentType && vatId && !vendorId
          ? this.renderConfidenceAddon(`${confidenceDataPath}.vatId`)
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderCountryInputGroup() {
    const { paymentType, vendorId, address: { countryCode } } = this.state;

    const { textsData: { uiTexts, countries } } = this.props;

    const buyPaymentType = paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY;

    const confidenceDataPath = `addresses.${buyPaymentType ? "sender" : "recipient"}`;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.country}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput disabled value={countryCode && countries[countryCode] ? `${countries[countryCode]} (${countryCode})` : ""} />
        {paymentType && countryCode && !vendorId
          ? this.renderConfidenceAddon(`${confidenceDataPath}.street`)
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderBankAccountInputGroup() {
    const { payTo: { bankAccount } } = this.state;

    const { textsData: { uiTexts } } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.bankAccount}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput
          name="payTo.bankAccount"
          disabled={this.documentFrozen}
          placeholder={uiTexts.enterBankAccount}
          value={bankAccount}
          onChange={this.handlePayToInputChange} />
        {bankAccount
          ? this.renderConfidenceAddon("payTo.bankAccount")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderBankInputGroup() {
    const { payTo: { bankCode } } = this.state;

    const { textsData: { uiTexts }, banksData } = this.props;

    let { payTo: { bankName } } = this.state;

    if (bankName) {
      if (!banksData.find(({ code }) => code === bankName)) {
        bankName = Constants.UNKNOWN_BANK_CODE;
      }
    }

    const unknownBank = bankName === Constants.UNKNOWN_BANK_CODE;

    return (
      <InputGroup className={Css.bankInputGroup}>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{`${uiTexts.bank}/${uiTexts.code}`}</span></InputGroupText>
        </InputGroupAddon>
        <FormSelect
          name="payTo.bankName"
          disabled={this.documentFrozen}
          placeholder={bankName ? undefined : ""}
          value={bankName}
          onChange={this.handlePayToInputChange}>
          {!bankName && <option value="">{uiTexts.selectBank}</option>}
          {unknownBank && <option value={Constants.UNKNOWN_BANK_CODE}>{uiTexts.unknownBank}</option>}
          {banksData.map(({ code, name }) => {
            return <option key={code} value={code}>{name}</option>;
          })}
        </FormSelect>
        <FormInput
          name="payTo.bankCode"
          disabled={this.documentFrozen}
          placeholder={Constants.EMPTY_PLACEHOLDER}
          value={bankCode}
          onChange={this.handlePayToInputChange} />
        {bankCode
          ? this.renderConfidenceAddon("payTo.bankCode")
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderExtraInputGroup(type) {
    const { extra } = this.state;

    const { textsData: { uiTexts } } = this.props;

    const fieldName = `extra.${type}`;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{type === "VS" ? uiTexts.variableSymbol : type}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput
          disabled={this.documentFrozen}
          name={fieldName}
          placeholder={Utils.replaceTextVars(uiTexts.enterTypeValue, { type })}
          value={extra[type]}
          onChange={this.handleExtraInputChange} />
        {extra[type]
          ? this.renderConfidenceAddon(fieldName)
          : this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderDescriptionInputGroup() {
    const { description } = this.state;

    const { textsData: { uiTexts } } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.description}</span></InputGroupText>
        </InputGroupAddon>
        <FormInput
          disabled={this.documentFrozen}
          placeholder={uiTexts.enterDescription}
          value={description}
          onChange={this.handleDescriptionInputChange} />
        {this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderTagsInputGroup() {
    const { tags, vendorId } = this.state;

    const { textsData: { uiTexts }, vendorsTags } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon type="prepend">
          <InputGroupText><span>{uiTexts.tags}</span></InputGroupText>
        </InputGroupAddon>
        <TagsInput
          disabled={this.documentFrozen}
          autoCompleteData={vendorId && vendorsTags}
          placeholder={uiTexts.addTags}
          value={tags}
          onChange={this.handleTagsInputChange} />
        {this.renderEmptyConfidenceAddon()}
      </InputGroup>
    );
  }

  renderEditContactBlock() {
    const { vendorId, paymentType, address } = this.state;

    const { fetchingContacts } = this.props;

    return (
      <EditDocumentWindowContactBlock
        key={vendorId}
        vendorId={vendorId}
        prefillData={!vendorId && address}
        documentFrozen={this.documentFrozen}
        disabled={!paymentType || vendorId || fetchingContacts || this.documentFrozen}
        onContactUnlink={this.handleEditContactBlockContactUnlink}
        onDataChange={this.handleEditContactBlockDataChange} />
    );
  }

  renderShowHistoryBlock() {
    return <EditDocumentWindowHistoryBlock documentId={this.props.editData.id} />;
  }

  renderShowCommentsBlock() {
    const { editData: { id }, comments } = this.props;

    return (
      <div className={Css.commentsBlockWrapper}>
        <CommentsBlock
          type={DataConstants.COMMENT_TARGET_TYPES.DOCUMENTS}
          id={id}
          comments={comments}
          className={Css.commentsBlock} />
      </div>
    );
  }

  renderEditDocumentRows() {
    const {
      textsData: { uiTexts }
    } = this.props;

    const {
      issueDate,
      detailedTaxRates,
      pohodaExtra: {
        vatClassification,
        preAccounting
      } = {}
    } = this.state;

    const receiptType = this.state.type === DataConstants.DOCUMENT_TYPES.RECEIPT;

    return (
      <FormGroup row>
        <Row form><Col>{this.renderPaymentTypeInputGroup()}</Col></Row>
        <Row form><Col>{this.renderDocumentTypeInputGroup()}</Col></Row>
        <Row form><Col>{this.renderOriginalIdInputGroup()}</Col></Row>
        {!receiptType && <Row form><Col>{this.renderExtraInputGroup("VS")}</Col></Row>}
        <Row form><Col>{this.renderLinkedDocumentsInputGroup()}</Col></Row>
        <div data-placeholder />
        <Row form><Col>{this.renderAmountBaseInputGroup()}</Col></Row>
        {detailedTaxRates.length
          ? <>
            {detailedTaxRates.map(({ id, name }) => {
              return <Row key={id} form><Col>{this.renderDetailedTaxRateInputGroup(id, name)}</Col></Row>;
            })}
          </>
          : <Row form><Col>{this.renderAmountVatInputGroup()}</Col></Row>}
        <Row form><Col>{this.renderAmountInputGroup()}</Col></Row>
        <Row form><Col>{this.renderAmountVatInputGroup()}</Col></Row>
        <div data-placeholder />
        <Row form><Col>{this.renderIssueDateInputGroup()}</Col></Row>
        <Row form><Col>{this.renderDueDateInputGroup()}</Col></Row>
        {!receiptType && <>
          <Row form><Col>{this.renderTaxDateInputGroup()}</Col></Row>
          <div data-placeholder />
          <Row form><Col>{this.renderBankAccountInputGroup()}</Col></Row>
          <Row form><Col>{this.renderBankInputGroup()}</Col></Row>
        </>}
        <div data-placeholder />
        <Row form><Col>{this.renderBusinessNameInputGroup()}</Col></Row>
        <Row form><Col>{this.renderCountryInputGroup()}</Col></Row>
        <Row form><Col>{this.renderBusinessIdInputGroup()}</Col></Row>
        <Row form><Col>{this.renderVatIdInputGroup()}</Col></Row>
        {!receiptType && <>
          <div data-placeholder />
          <Row form><Col>{this.renderExtraInputGroup("KS")}</Col></Row>
          <Row form><Col>{this.renderExtraInputGroup("SS")}</Col></Row>
        </>}
        <div data-placeholder />
        <Row form>
          <Col>
            {this.renderDescriptionInputGroup()}
          </Col>
        </Row>
        <Row form><Col>{this.renderTagsInputGroup()}</Col></Row>
        <div data-placeholder />
        <div className={Css.title}>{uiTexts.onlyForPohoda}</div>
        <Row form>
          <Col>
            <VatClassificationComponent
              issueDate={issueDate}
              value={vatClassification}
              disabled={this.documentFrozen}
              onChange={this.handleVatClassificationChange} />
          </Col>
        </Row>
        <Row form>
          <Col>
            <PreAccountingComponent
              value={preAccounting}
              disabled={this.documentFrozen}
              onChange={this.handlePreAccountingChange} />
          </Col>
        </Row>
      </FormGroup>
    );
  }

  renderOldDocumentForm() {
    const { DEFAULT, LINE_ITEMS, CONTACT, HISTORY, COMMENTS } = EDIT_DOCUMENT_WINDOW_MODES;

    const {
      textsData: { uiTexts },
      editData,
      selectedBusinessData,
      fetchingContacts,
      fetchingEventsData
    } = this.props;

    const {
      selectedEditTab,
      vendorId,
      enabledButtons
    } = this.state;

    const { attachment = {}, extraData: { xeroSourceUrl, zohoSourceUrl } = {} } = editData;

    const { extraData: { integrationService } = {} } = selectedBusinessData;

    const renderEditModeBlock = {
      [DEFAULT]: () => this.renderEditDocumentRows(),
      [LINE_ITEMS]: () => null,
      [CONTACT]: () => this.renderEditContactBlock(),
      [HISTORY]: () => this.renderShowHistoryBlock(),
      [COMMENTS]: () => this.renderShowCommentsBlock()
    }[selectedEditTab];

    return (
      <Form className={Css.form} onSubmit={this.handleFormSubmit}>
        <Row className={Css.row}>
          <Col className={Css.dataFieldsColumn}>
            <FormGroup row className={Css.titleRow}>
              <EditDocumentWindowModeSelector
                status={editData.status}
                comments={editData.comments}
                vendorId={vendorId}
                selectedEditTab={selectedEditTab}
                disabled={fetchingContacts || fetchingEventsData || !enabledButtons}
                onChange={this.handleModeSelectorChange} />
            </FormGroup>
            {(renderEditModeBlock()) || this.renderEditDocumentRows()}
          </Col>
          <Col className={Css.attachmentColumn}>
            <FormGroup row className={Css.titleRow}>
              <div className={Css.downloadLinkContainer} onClick={this.handleDownloadLinkClick}>
                <a disabled={!attachment.key}>
                  <FiDownload />
                  <span>{attachment.originalName || uiTexts.download}</span>
                </a>
              </div>
              {!!(xeroSourceUrl || zohoSourceUrl) && <div
                className={Css.externalLinkContainer}
                onClick={this.handleExternalLinkClick}>
                <a>
                  <FiExternalLink />
                  <span>
                    {Utils.replaceTextVars(
                      uiTexts.openInService,
                      { service: IntegrationServices.getByValue(integrationService).label }
                    )}
                  </span>
                </a>
              </div>}
            </FormGroup>
            <FormGroup row className={classNames(Css.pdfViewerContainer, CommonCss.flexCenter)}>
              <PdfViewer key={attachment.key} attachment={attachment} />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }

  render() {
    const { CONTACT } = EDIT_DOCUMENT_WINDOW_MODES;

    const { STATUSES } = DataConstants;

    const {
      textsData: { uiTexts },
      editData,
      fetchingData,
      fetchingContacts,
      fetchingEventsData
    } = this.props;

    const {
      selectedEditTab,
      newContactData,
      type,
      linkedDocumentsIds,
      vendorId,
      enabledButtons,
      linkDocumentsWindowOpened,
      fullDocumentLoaded
    } = this.state;

    const documentId = editData.originalDocumentId || "";

    const businessName = (editData.vendorId && editData.address && editData.address.name) || "";

    const { fullName, email } = editData.createdBy || {};

    const createdBy = (fullName ? fullName + (fullName === email ? "" : ` <${email}>`) : email)
      || uiTexts.unknown.toLowerCase();

    const createdAt = moment(editData.createdAt).format(Constants.DATETIME_FORMATS.DATETIME_TEXT);

    const titleText = <>
      <b>{documentId ? `#${documentId}` : ""}</b>
      <span data-muted={businessName ? "" : undefined}>
        {businessName
          ? `${documentId ? " | " : ""}${businessName}`
          : `${documentId ? " — " : ""}${uiTexts.editDocument}`}
      </span>
    </>;

    const subTitleText = <>
      <span>{`${uiTexts.uploadedBy}: `}</span>
      <b>{createdBy}</b>
      <span>{`, ${createdAt}`}</span>
    </>;

    return ReactDom.createPortal(
      <Modal
        open
        id={this.uniqueId}
        fade={false}
        className={Css.modalWindow}
        modalClassName={classNames(
          Css.editDocumentWindowContainer,
          Css.czCountry,
          Css.editMode,
          `mode-${selectedEditTab}`,
          this.uniqueId
        )}
        toggle={this.toggleModal}>
        <div className={classNames(Css.modalHeaderWrapper, CommonCss.zIndexA)}>
          <EditDocumentWindowHeader
            titleText={titleText}
            subTitleText={subTitleText}
            documentIndex={this.currentDocumentIndex}
            documentsCount={this.extractedDocumentsData.length}
            disabledButtons={fetchingData || fetchingContacts || fetchingEventsData || !enabledButtons}
            duplicatedDocument={!!(editData.duplicatedDocumentsIds && editData.duplicatedDocumentsIds.length)}
            onDocumentSwitch={this.handleHeaderDocumentSwitch}
            onClose={this.handleClose} />
        </div>
        <ModalBody className={Css.modalBody}>
          {fullDocumentLoaded
            ? this.renderOldDocumentForm()
            : <Preloader absolute />}
        </ModalBody>
        <div className={classNames(Css.modalFooterWrapper, CommonCss.zIndexA)}>
          <EditDocumentWindowFooter
            selectedEditTab={selectedEditTab}
            additionalBlockData={newContactData}
            showToReportButton={editData && editData.status === STATUSES.TO_REVIEW}
            showAddContactButton={selectedEditTab === CONTACT && !vendorId}
            disabledOkButton={(editData && !this.checkFormHasChanges())}
            showExcludeButton={editData.status !== STATUSES.EXCLUDED}
            disabledToReportButton={!this.checkFormValidity(this.state)}
            disabledAddContactButton={!newContactData}
            disabledButtons={fetchingData || fetchingContacts || fetchingEventsData || !enabledButtons}
            onAdditionalBlockClose={this.handleAdditionalBlockClose}
            okButtonText={this.documentFrozen ? <FiLock /> : uiTexts.saveAndClose}
            onClose={this.handleClose} />
        </div>
        {linkDocumentsWindowOpened && (
          <LinkDocumentsWindow
            documentType={type}
            documentsIds={linkedDocumentsIds}
            documentFrozen={this.documentFrozen}
            onChange={this.handleLinkDocumentsWindowChange}
            onClose={this.handleLinkDocumentsWindowClose} />
        )}
      </Modal>,
      window.document.getElementById("portal")
    );
  }
}

export default EditDocumentWindow;
